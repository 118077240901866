export const ICON_NAMES = {
  ACCOUNT: 'ACCOUNT',
  ADMIN: 'ADMIN',
  APPS: 'APPS',
  BACK_ARROW: 'BACK_ARROW',
  CALENDAR: 'CALENDAR',
  CALL_MADE: 'CALL_MADE',
  CARET: 'CARET',
  CHECK: 'CHECK',
  CHEVRON: 'CHEVRON',
  CLEAR: 'CLEAR',
  CLOSE: 'CLOSE',
  CUBES: 'CUBES',
  DATA_OBJECT: 'DATA_OBJECT',
  DISCORD: 'DISCORD',
  FIGURE: 'FIGURE',
  GITHUB: 'GITHUB',
  HASH: 'HASH',
  HELP: 'HELP',
  HELP_OUTLINE: 'HELP_OUTLINE',
  IN_PROGRESS: 'IN_PROGRESS',
  INVENTORY: 'INVENTORY',
  KEY: 'KEY',
  LINE_CHART: 'LINE_CHART',
  LOGO: 'LOGO',
  LOGOUT: 'LOGOUT',
  MEGAPHONE: 'MEGAPHONE',
  MENU: 'MENU',
  MOON: 'MOON',
  PARTICIPATION: 'PARTICIPATION',
  PROVENANCE: 'PROVENANCE',
  PENDING: 'PENDING',
  PRICE: 'PRICE',
  RAINBOW: 'RAINBOW',
  REPORTS: 'REPORTS',
  SEARCH: 'SEARCH',
  SETTINGS: 'SETTINGS',
  SHARED_POOLS: 'SHARED_POOLS',
  SLACK: 'SLACK',
  SUN: 'SUN',
  TRASH: 'TRASH',
  UPGRADE: 'UPGRADE',
  WARNING: 'WARNING',
};
